
import { defineComponent } from "vue-demi";
import { mask } from 'vue-the-mask';
import { Product } from "@/interfaces/product/product.dto";
import { Cart } from "@/interfaces/cart/cart.dto";
import { useAPI } from "@/use";
import { EmptyImageIcon, MinusIcon, PlusIcon } from '@/plugins/icons';
import Preloader from '@/components/common/preloader/index.vue';
import { PRODUCT_TYPES } from "@/utils/enums";
import { PRODUCT_TYPES_NAMES } from "@/utils/enums/names";

import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import '@/assets/css/slider.css';

export default defineComponent({
  name: 'MainCatalogProductMain',
  directives: {
    mask
  },
  props: {
    product_id: String
  },
  data() {
    return {
      slide: ['1.jpg', '2.jpg', '3.jpg'],
      product: null as Product.Dto | null,
      cart: null as Cart.Dto | null,
      quantity: 0
    }
  },
  async created() {
    await this.getProductMethod();
    this.getCartMethod();
  },
  computed: {
    isProductInCart() {
      if (!this.cart || !this.product) return false;
      return this.cart.products.find(p => p.product._id === this.product?._id);
    },
    currentType() {
      if (!this.product) return '';
      return `${PRODUCT_TYPES_NAMES[this.product.type]}${this.product.type === PRODUCT_TYPES.PACK ? ` ${this.product.unitsPerPack} шт` : ''}`;
    },
    currentPrice() {
      if (!this.product) return '';
      return this.product.type === PRODUCT_TYPES.UNIT ? this.product.pricePerUnit : this.product.pricePerPack;
    },
    currentImages() {
      if (!this.product) return [];
      return this.product.image.map(i => i.src);
    }
  },
  methods: {
    async getProductMethod() {
      const result = await useAPI().product.getMethod(this.product_id ?? '');
      this.product = result.data;
      console.log();
      
    },
    async getCartMethod() {
      const result = await useAPI().cart.getMethod();
      this.cart = result.data;
      this.productCartQuantity();
    },
    async toggleProductCart(inc: number) {
      if (!this.product || this.quantity === 0 && inc === -1) return;

      const result = await useAPI().cart.toggleProductMethod(this.product._id, inc);
      this.cart = result.data;
      this.quantity = Number(this.quantity) + inc;
      this.$store.commit('setProductLength', result.data.products.reduce((acc: number, p: { quantity: number }) => acc + p.quantity, 0));
    },
    async updateQuantity() {
      if (!this.product) return;
      if (Number(this.quantity) > 25) this.quantity = 25;

      const result = await useAPI().cart.updateProductQuantityMethod(this.product._id, Number(this.quantity));
      this.cart = result.data;
      this.$store.commit('setProductLength', result.data.products.reduce((acc: number, p: { quantity: number }) => acc + p.quantity, 0));
    },
    productCartQuantity() {
      if (!this.cart || !this.product) return this.quantity = 0;
      this.quantity = this.cart.products.find(p => p.product._id === this.product?._id)?.quantity || 0;
    },
  },
  components: {
    Preloader,
    Carousel, Slide, Pagination, Navigation,
    EmptyImageIcon,
    MinusIcon, 
    PlusIcon,
  }
})
