
import { defineComponent } from "vue-demi";
import CatalogProductMain from "@/components/main/catalogProduct/index.vue";

export default defineComponent({
  name: "CatalogProductView",
  components: {
    CatalogProductMain,
  },
});
