export enum ROLES_NAME {
  OWNER = 'владелец',
  ADMIN = 'админ',
  PARTNER = 'партнер',
  USER = 'клиент',
}

export enum PRODUCT_TYPES_NAMES {
  UNIT = 'Поштучно',
  PACK = 'В упаковке'
}

export enum STATUS_NAMES {
  UNPAID = 'неоплачен',
  PAID = 'оплачен',
  CREATED = 'создан',
  ASSEMBLED = 'собран',
  RECEIVED = 'получен',
  SENT = 'отправлен',
  DELIVERED = 'доставлен',
  DELETED = 'удален'
}