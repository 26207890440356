import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-595c8ca6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "catalog-product flex items-start"
}
const _hoisted_2 = {
  key: 0,
  class: "images slider-product flex-column"
}
const _hoisted_3 = {
  key: 0,
  alt: ""
}
const _hoisted_4 = {
  key: 1,
  class: "images"
}
const _hoisted_5 = { class: "image" }
const _hoisted_6 = { class: "info flex-column" }
const _hoisted_7 = { class: "title leksa_title_50" }
const _hoisted_8 = { class: "weight alsb" }
const _hoisted_9 = { class: "description als lh26 fz20" }
const _hoisted_10 = { class: "price leksa" }
const _hoisted_11 = { class: "cart flex" }
const _hoisted_12 = { class: "inc flex items-center" }
const _hoisted_13 = { class: "inc--quantity leksa_title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slide = _resolveComponent("slide")!
  const _component_navigation = _resolveComponent("navigation")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_carousel = _resolveComponent("carousel")!
  const _component_empty_image_icon = _resolveComponent("empty-image-icon")!
  const _component_minus_icon = _resolveComponent("minus-icon")!
  const _component_plus_icon = _resolveComponent("plus-icon")!
  const _component_preloader = _resolveComponent("preloader")!
  const _directive_lazy = _resolveDirective("lazy")!
  const _directive_mask = _resolveDirective("mask")!

  return (_ctx.product)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (_ctx.product.image.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_carousel, {
                "items-to-show": 1,
                "wrap-around": true
              }, {
                addons: _withCtx(() => [
                  _createVNode(_component_navigation),
                  _createVNode(_component_pagination)
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.image, (image) => {
                    return (_openBlock(), _createBlock(_component_slide, {
                      key: image._id
                    }, {
                      default: _withCtx(() => [
                        image
                          ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_3, null, 512)), [
                              [_directive_lazy, image.src]
                            ])
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_empty_image_icon)
              ])
            ])),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.product.name), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.currentType) + ", " + _toDisplayString(_ctx.product.length) + "x" + _toDisplayString(_ctx.product.height) + " мм, " + _toDisplayString(_ctx.product.weight) + " г", 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.product.description), 1),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.currentPrice) + "₽", 1),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", {
                class: "inc--block cursor flex-center",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleProductCart(-1)))
              }, [
                _createVNode(_component_minus_icon)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.quantity) = $event)),
                  onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateQuantity && _ctx.updateQuantity(...args)))
                }, null, 544), [
                  [_directive_mask, '##'],
                  [_vModelText, _ctx.quantity]
                ])
              ]),
              _createElementVNode("div", {
                class: "inc--block cursor flex-center",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleProductCart(1)))
              }, [
                _createVNode(_component_plus_icon)
              ])
            ]),
            _createElementVNode("button", {
              class: "btn btn--main btn--yellow",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleProductCart(1)))
            }, "Добавить в корзину")
          ])
        ])
      ]))
    : (_openBlock(), _createBlock(_component_preloader, { key: 1 }))
}